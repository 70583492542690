// @flow

import React from 'react';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import { getSoftQuoteBaseUrl } from '@/utils/getUrlFromEnvUtil';
import {SOFT_QUOTE_AU_URL, SOFT_QUOTE_BROKER_URL, SOFT_QUOTE_KB_URL, SOFT_QUOTE_NZ_URL } from '@/utils/constants';
import EstimateRateWidget from './EstimateRateWidget';
import { getDefaultLoanAmount } from './MainRateWidget';
import { LOAN_PURPOSE_AU } from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';

export const EstimateRateWidgetSection = (props: { purpose?: string }) => {
  return (
    <div
      id="check-your-rate"
      css={`
        position: relative;
        z-index: 1;
        & > div {
          @media (max-width: ${BREAKPOINT.LG}) {
            padding: 16px 16px 0;
            margin-bottom: -26px;
          }
          @media (min-width: ${BREAKPOINT.LG}) {
            padding-bottom: 38px;
          }
        }
      `}
    >
      <ConfettiFramedContainer>
        <EstimateRateWidget
          getRate={() => {
            if (!sessionStorage.getItem('purpose') && props.purpose) {
              sessionStorage.setItem('purpose', props.purpose);
            }
            if (typeof window !== 'undefined') {
              window.location = redirectUrl();
            }
          }}
          values={props.purpose && { ...{ purpose: props.purpose } }}
          main
        />
      </ConfettiFramedContainer>
    </div>
  )
};

export const redirectUrl = (channel: string, isBetterStart: boolean = false) => {
  let quoteUrl = '';
  let isBroker = false;
  let softQuoteBaseUrl = getSoftQuoteBaseUrl();
  if (channel === 'broker') {
    isBroker = true;
    quoteUrl = `${softQuoteBaseUrl}${SOFT_QUOTE_BROKER_URL}`;
  } else if (channel === 'gem') {
    quoteUrl = `${softQuoteBaseUrl}${SOFT_QUOTE_NZ_URL}`;
  } else if (channel === 'kiwibank') {
    quoteUrl = `${softQuoteBaseUrl}${SOFT_QUOTE_KB_URL}`;
  } else {
    quoteUrl = `${softQuoteBaseUrl}${SOFT_QUOTE_AU_URL}`;
  }
  // TODO: For local testing only
  // quoteUrl = SOFT_QUOTE_AU_URL;

  const amount = (typeof window !== 'undefined' && sessionStorage.getItem('loanAmount')) ?? getDefaultLoanAmount({ isGem:false, isBroker, isBetterStart});
  const purpose = (typeof window !== 'undefined' && sessionStorage.getItem('purpose')) ?? LOAN_PURPOSE_AU.NOT_SELECTED;
  const purposeOther = (typeof window !== 'undefined' && sessionStorage.getItem('purposeOther')) ?? '';
  let purposeOtherText = purpose === LOAN_PURPOSE_AU.OTHER ? `&other=${purposeOther}` : '';
  const repaymentPeriod = (typeof window !== 'undefined' && sessionStorage.getItem('repaymentPeriod')) ?? '5';
  const redirectUrl = `${quoteUrl}?amount=${amount}&purpose=${purpose}&period=${repaymentPeriod}${purposeOtherText}`;
  return redirectUrl;
};

export function resetToDefault(isBroker: boolean =  false, purpose: string = LOAN_PURPOSE_AU.NOT_SELECTED, isGem: boolean =  false, isBetterStart: boolean = false){
  if (typeof window !== 'undefined') {
    sessionStorage.setItem('loanAmount', getDefaultLoanAmount({ isGem, isBroker, isBetterStart}));
    sessionStorage.setItem('purpose', purpose);
    sessionStorage.setItem('repaymentPeriod', isGem ? '7' : '5');
    sessionStorage.setItem('purposeOther', '');
  }
}

import React from 'react';
import styled from 'styled-components';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import {redirectUrl, resetToDefault} from '@/components/EstimateRateWidget/EstimateRateWidgetSection';

const WidgetIframe = ({ location, props }) => {
  resetToDefault();
  return (
    <Layout location={location} noHeader noFooter>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            html, body, #___gatsby, #___gatsby > div { 
              width: 100%; height: 100%; background-color: #F8F8F8; 
            }
          `
        }}
      />
      <Metadata title="widget embed" robots="noindex" />
      <Outer>
        <Inner>
          <ConfettiFramedContainer>
            <EstimateRateWidget
              getRate={() => {
                if (!sessionStorage.getItem('purpose') && props?.purpose) {
                  sessionStorage.setItem('purpose', props.purpose);
                }
                if (typeof window !== 'undefined') {
                  window.open(redirectUrl('gem'), '_blank');
                }
              }}
              main
            />
          </ConfettiFramedContainer>
        </Inner>
      </Outer>
    </Layout>
  );
};

const Outer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

const Inner = styled.div`
  width: 100%;
`;

export default WidgetIframe;
